* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

ul {
  list-style-type: none;
}

a {
  text-decoration: none;
  color: rgba(86, 29, 94);
  font-size: 1.2em;
  letter-spacing: 1.7px;
}

a:hover {
  font-size: 1.4em;
}

.container {
  margin: auto;
  background: rgba(0, 0, 0, 1);

}


.navbar {
  width: 100%;
  height: 70px;
  top:0;
  left:0;
  box-shadow: rgba(0, 0, 0, 0.9) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
}

.navbar > .container {
  height: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 1rem;
}

.nav-menu {
  display: flex;
}

.navbar li {
  padding: 1rem;
}

.hamburger {
  display: none;
  font-size: 3rem;
  color: rgba(86, 29, 94);
}

@media screen and (max-width:940px) {
  .hamburger {
    display: block;
  }

  .navbar .nav-menu {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    top: 70px;
    height: 100%;
    right: 100%;
    width: 100%;
    transition: 0.4s ease-in-out;
    z-index: 10;
    background: rgba(0, 0, 0, 0.9);
  }
  .navbar .active {
    right: 0;
  }

  .nav-menu li a {
    font-size: 1.5rem;
  }
}

.container {
  background: rgba(0, 0, 0, 0.9) !important;
}

.hero-title {
  color: rgba(86, 29, 94);

}
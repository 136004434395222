.hero {
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  height: 80vh;
  width:100%;
  position: relative;
  margin-bottom: 4rem;
}

.hero:before {
  content: '';
  background: url('../public/hero-img-one.jpg') no-repeat center center/cover;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.hero > .content {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 700px;
  margin: auto;
  padding: 1rem;
}

.hero h2 {
  width: 100%;
  font-size: 3.5rem;
  text-transform: capitalize;
  text-align: center;
  background: rgba(0, 0, 0, 0.6);
  margin-bottom: 0.1rem;
  padding: 1rem;
  border-right: 1px solid rgba(147,112,219, 0.5);
  border-left: 1px solid rgba(147,112,219, 0.5);


}

.hero p {
  font-size: 1.2rem;
  text-align: center;
  background: rgba(0, 0, 0, 0.6);
  padding: 1.1rem;
  border-right: 1px solid rgba(147,112,219, 0.5);
  border-left: 1px solid rgba(147,112,219, 0.5);
  
}

.self-typed {
  color: white;
  letter-spacing: 2px;
  font-size: 1.5em;
  text-transform: uppercase;

}

.self-typed-container {
  width: 100%;
  background: rgba(0, 0, 0, 0.6);
  padding: 1rem;
  border-right: 1px solid rgba(147,112,219, 0.5);
  border-left: 1px solid rgba(147,112,219, 0.5);
  margin-top: 0.1rem;
  text-align: center;
}

.contact-us-btn {
  color: white;
  padding: 1.1rem;
  text-transform: uppercase;
  letter-spacing: 2px;
  background: rgba(147,112,219, 0.8);

  margin-top: 0.3rem;
  border: none;
}

.contact-us-btn:hover {
  background: rgba(147,112,219, 0.5);
  font-weight: bold;
  letter-spacing: 2.1px;
  cursor: pointer;
  border: 1px solid white;
}

.about-us-btn:hover {
  background: rgba(0, 0, 0, 0.4);
  font-weight: bold;
  letter-spacing: 2.1px;
  cursor: pointer;
  border: 1px solid white;
}

.about-us-btn {
  background: rgba(0, 0, 0, 0.6);
  color: white;
  padding: 1.1rem;
  text-transform: uppercase;
  letter-spacing: 2px;

  margin-top: 0.3rem;
  margin: 0.2rem;
  border: none;
  

}

.hero-title {
  color: #9370DB;
  
}

.hero > .content {
  display: flex;
  justify-items: center;
  align-items: center;
}


.services-main-container {
  width: 100%;
  margin: 4rem 0;
  text-align: center;
}

.underline {
  margin: auto;
  margin-top: -30px;
  margin-bottom: 30px;
  width:15%;
  border: 1px solid #8A2BE2;
}

.services-container {
  width: 80%;
  margin: 4rem 0;
  text-align: center;
  margin: 0 auto;

}

.services-container h2 {
  margin-bottom: 2rem;
}

.services-container p {
  margin-bottom: 1rem;
  
}

.service-help {
  font-weight: bold;
  font-size: 1.2rem;
}

.services-list {
  display: flex;
  justify-content: space-around;
}

.additional-service-container-outer {
  background-color: #F5F5F5;
  width: 100%;
  margin: 4rem 0;
  text-align: center;
  flex: 1;
  padding: 1rem;

}


.additional-service-container-inner {
  width: 80%;
  margin: 3rem 0;
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
  text-align: left;
  
}


.additional-service-container-outer img {
  width: 100%;
  max-width: 450px;
  height: 70vh;
  align-self: center;
}

.additional-service-container-outer h2 {
  margin: 1rem 0;
  margin-left: 1em;


}


.additional-service-container-inner p {
  padding: 1.2rem;
}

.point-styling::before {
  content: "\2022";
  color: black;
  font-weight: bold;
  display: inline-block; 
  width: 1em;
  margin-bottom: 1rem;
  margin-left: 2em;


}

.service-icons {
  font-size: 3rem;
  padding: 1rem;
  color: #8A2BE2;
}

@media screen and (max-width:940px) {
  .additional-service-container-outer img {
    max-width: 950px;

  }
  .services-list, .additional-service-container-inner {
    flex-direction: column-reverse;
    text-align: center;
  }

  .additional-service-img {
    margin: 0 auto;
    

  }

  
  .point-styling {
    text-align: left;
  } 
  
}
/* start of footer styling */
footer > section > div > ul > li, footer > section  > ul > li {
  list-style: none;
  padding-left: 0;
}
.footer-link {
  text-decoration: none;
  color:white;
}

.main-item {
  display: flex;
}

.main-item li {
  margin-bottom: 0.5em;
  padding: 1rem;
}

.footer-link:hover {
  color:#bbada0;
}

.footer-heading {
    font-size: 1.275rem;
    padding-bottom: 0.625rem;
    text-align: center;
    color:rgb(120,81,169);
    margin-bottom: -30px;
    margin-top: 100px;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: 500;
}

footer {
  position: absolute;
  width: 100%;
  height: 80vh;
  line-height: 1.6;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  
}

.main-footer {
  padding: 1.25rem 1.875rem;
  display: flex;
  flex-wrap: wrap;
}

.main-item {
  padding: 1.25rem;
  min-width: 12.5rem;
}

.social-section {
  padding: 0 1.875rem 1.25rem;
}

.social-list {
  display: flex;
  justify-content: center;
  padding-top: 1.25rem;

}

.social-list li {
  margin: 0.5rem;
  font-size: 1.25 rem;
}

.legal-section {
  padding: 5rem 1.875rem 1.25rem;

}

.legal-list {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.legal-list li {
  margin: 0.125rem 0.625rem;
  white-space: nowrap;
}

.legal-list li:nth-last-child(2) {
  flex: 1;
}

.ft-icon {
  font-size: 20px;
  margin-right: 0.6em;
  color:rgb(120,81,169, 0.8);


}

.ft-icon-social {
  border: none;
  position: relative;
  font-size: 40px;
  margin-right: -18px;
  outline: none;
  height: 50px;
  width: 55px;
  margin: 5px;
  cursor: pointer;
  background-color: #fff;
  overflow: hidden;
  border-radius: 2px;
  color: white;
}


.facebook {
  background-color: #3b5999;

}
.facebook::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #3b5999;
  top: 100%;
  left:0;
  transition: 0.2s top;

}

.facebook:hover::before {
  top: 0;
}
.twitter {
  background-color: #55acee;

}

.twitter::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #55acee;
  top: 100%;
  left:0;
  transition: 0.2s top;

}

.twitter:hover::before {
  top: 0;
}

.whatsapp {
  background: rgba(37,211,102, 0.7);

}

.whatsapp::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 100%;
  left:0;
  transition: 0.2s top;
  background-color: #25d366;

}

.whatsapp:hover::before {
  top: 0;
}

.youtube {
  background-color: #ff0000;

}

.youtube::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #ff0000;
  top: 100%;
  left:0;
  transition: 0.2s top;

}

.youtube:hover::before {
  top: 0;
}


.icon {
  position: relative;
  z-index: 2;
  transition: 0.7s color, 0.7s transform;

}

.icon:hover {
  transform: rotate(360deg);

}


.copyright {
  color: black;
}


.ft-links {
  display: flex;
}

.ft-links li { 
  margin-right: 1em;
}

.ft-icon-social:hover {
  color:white;

}

.ft-links li {
  color:rgb(120,81,169, 0.8);
  font-weight: bold;
  text-transform: uppercase;
}


.footer-link {
  display: flex;
  align-items: center;
  line-height: 2.5;

}

.copyright { 
  color: purple;
  padding: 2rem;
}
/* end of footer styling */

@media (max-width: 848px) {
  
  .legal-section {
    padding: 2rem 1.875rem 1.25rem;

  }

  .ft-links {
    flex-direction: column;
  }
  
  .ft-links li { 
    margin-bottom: 0.5em;
  }

}

@media only screen and (min-width: 500px) {
  .main-footer {
      justify-content: space-around;
  }
}
@media only screen and (min-width: 1240px ) {
  .main-footer {
    justify-content: space-evenly;
  }
  
  
}
